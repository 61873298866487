import { TinyEmitter } from "tiny-emitter";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IIFEBusEventCallbackSignatures {
  "do:action-menu:open": () => void;
  "do:action-menu:close": () => void;
  "do:action-menu:toggle": () => void;
  "do:action-menu:show": () => void;
  "do:action-menu:hide": () => void;
  "do:authentication:login": (encryptedCustomerId: string) => void;
  "do:authentication:logout": () => void;
  "do:authentication:show": () => void;
  "is:action-menu:open": () => void;
  "is:action-menu:close": () => void;
  "is:action-menu:show": () => void;
  "is:action-menu:hide": () => void;
}

export type IIFEBusEvent = keyof IIFEBusEventCallbackSignatures;

export type IIFEBusEventCallback<
  T extends keyof IIFEBusEventCallbackSignatures,
> = IIFEBusEventCallbackSignatures[T];

declare class IIFETinyEmitterWithEvents extends TinyEmitter {
  public e: Record<
    IIFEBusEvent,
    { ctx: unknown; fn: IIFEBusEventCallback<IIFEBusEvent> }[]
  >;
}

export class IIFEBus {
  readonly #debouncing: Set<string>;
  readonly #emitter: IIFETinyEmitterWithEvents;

  constructor() {
    this.#debouncing = new Set<string>();
    this.#emitter = new TinyEmitter() as IIFETinyEmitterWithEvents;
  }

  public on<T extends IIFEBusEvent>(
    event: T,
    callback: IIFEBusEventCallback<T>,
    ctx?: unknown,
  ) {
    this.#emitter.on(event, callback, ctx);
  }

  public once<T extends IIFEBusEvent>(
    event: T,
    callback: IIFEBusEventCallback<T>,
    ctx?: unknown,
  ) {
    this.#emitter.once(event, callback, ctx);
  }

  public off<T extends IIFEBusEvent>(
    event: T,
    callback?: IIFEBusEventCallback<T>,
  ) {
    this.#emitter.off(event, callback);
  }

  public emit<T extends IIFEBusEvent>(
    event: T,
    ...args: Parameters<IIFEBusEventCallback<T>>
  ) {
    const debounceKey = window.btoa(
      [event, ...args].map((p) => JSON.stringify(p)).join(","),
    );
    if (this.#debouncing.has(debounceKey)) {
      return;
    }
    this.#debouncing.add(debounceKey);
    setTimeout(() => {
      this.#debouncing.delete(debounceKey);
    }, 300);
    this.#emitter.emit(event, ...args);
    this.#emitToWindow(event, ...args);
  }

  #emitToWindow<T extends IIFEBusEvent>(
    event: T,
    ...args: Parameters<IIFEBusEventCallback<T>>
  ) {
    if (typeof window !== "undefined") {
      window.dispatchEvent(
        new CustomEvent(`m-chat-iife-bus`, {
          detail: {
            event,
            args,
            fromBus: true,
          },
        }),
      );
    }
  }

  #onEventFromWindow = (event: Event) => {
    if ((event as CustomEvent).detail) {
      if (
        (event as CustomEvent).detail.event &&
        (event as CustomEvent).detail.fromBus !== true
      ) {
        this.emit(
          (event as CustomEvent).detail.event,
          ...((event as CustomEvent).detail.args ?? []),
        );
      }
    }
  };

  #onActionMenuOpen = () => {
    this.emit(`is:action-menu:open`);
  };

  #onActionMenuClose = () => {
    this.emit(`is:action-menu:close`);
  };

  #onActionMenuShow = () => {
    this.emit(`is:action-menu:show`);
  };

  #onActionMenuHide = () => {
    this.emit(`is:action-menu:hide`);
  };

  public install(window?: globalThis.Window | undefined) {
    if (window) {
      window.addEventListener(`m-chat-iife-bus`, this.#onEventFromWindow);
      window.addEventListener(
        `m-chat-action-menu-open`,
        this.#onActionMenuOpen,
      );
      window.addEventListener(
        `m-chat-action-menu-close`,
        this.#onActionMenuClose,
      );
      window.addEventListener(
        `m-chat-action-menu-show`,
        this.#onActionMenuShow,
      );
      window.addEventListener(
        `m-chat-action-menu-hide`,
        this.#onActionMenuHide,
      );
    }
  }

  public uninstall(window?: globalThis.Window | undefined) {
    if (window) {
      window.removeEventListener(`m-chat-iife-bus`, this.#onEventFromWindow);
      window.removeEventListener(
        `m-chat-action-menu-open`,
        this.#onActionMenuOpen,
      );
      window.removeEventListener(
        `m-chat-action-menu-close`,
        this.#onActionMenuClose,
      );
      window.removeEventListener(
        `m-chat-action-menu-show`,
        this.#onActionMenuShow,
      );
      window.removeEventListener(
        `m-chat-action-menu-hide`,
        this.#onActionMenuHide,
      );
    }
  }
}

export const postMessageToIIFE = (event: IIFEBusEvent, ...args: unknown[]) => {
  if (typeof window !== "undefined") {
    window.dispatchEvent(
      new CustomEvent(`m-chat-iife-bus`, {
        detail: {
          event,
          args,
        },
      }),
    );
  }
};
